'use client'

import { ReactNode, useEffect, useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { MultiValue } from 'react-select'
import {
  AsyncMultipleSearchWithSelect,
  AsyncMultipleSearchWithSelectProps
} from '~/core/ui/AsyncMultipleSearchWithSelect'
import {
  AsyncSingleSearchWithSelect,
  AsyncSingleSearchWithSelectProps
} from '~/core/ui/AsyncSingleSearchWithSelect'
import { MultipleSelect } from '~/core/ui/MultipleSelect'
import { NativeSelect } from '~/core/ui/NativeSelect'
import {
  IPromiseSearchOption,
  ISelectOption,
  SelectClassControl,
  SelectOptionProps,
  SelectProps,
  SelectSizeProps
} from '~/core/ui/Select'
import {
  Tooltip,
  tooltipAlignProps,
  tooltipPositionProps
} from '~/core/ui/Tooltip'
import { cn } from '~/core/ui/utils'
import {
  DatePickerValue,
  SingleDateWithYearOnlyPicker
} from './SingleDateWithYearOnlyPicker'

interface InlineEditingNoActionsOptionsProps {
  children?: ReactNode
  component?: ReactNode
  onClick?: () => void
  onCancel?: () => void

  tooltipElement?: {
    title: string
    position?: tooltipPositionProps
    align?: tooltipAlignProps
  }
  tooltipError?: {
    position?: tooltipPositionProps
    align?: tooltipAlignProps
  }

  edited?: boolean
  setEdited?: (selected: boolean) => void
  autoSave?: boolean
  destructiveText?: string

  overlayEdited?: boolean
}

const InlineEditingNoActionsControl = (
  props: InlineEditingNoActionsOptionsProps
) => {
  const {
    children,
    onClick,
    onCancel,
    component,
    edited,
    setEdited,
    tooltipElement,
    tooltipError,
    autoSave,
    destructiveText,
    overlayEdited = false
  } = props

  const ref = useOnclickOutside(
    () => {
      if (onClick && edited) {
        if (autoSave) {
          onClick()
        } else {
          onCancel && onCancel()
        }
      }
    },
    {
      ignoreClass: 'my-ignore-action'
    }
  )

  const renderedView = () => {
    if (overlayEdited) {
      return (
        <>
          {edited ? (
            <div
              ref={ref}
              className={cn('relative bottom-0 left-0 right-0 top-0 z-10')}
            >
              <Tooltip
                open={!!destructiveText}
                position={tooltipError?.position || 'top'}
                align={tooltipError?.align || 'end'}
                content={destructiveText}
                classNameConfig={{
                  content: !!destructiveText ? '' : 'hidden'
                }}
              >
                <div className="h-full bg-white">{component}</div>
              </Tooltip>
            </div>
          ) : (
            <Tooltip
              classNameConfig={{
                content: !tooltipElement?.title ? 'hidden' : ''
              }}
              content={tooltipElement?.title}
              position={tooltipElement?.position || 'top'}
              align={tooltipElement?.align || 'end'}
            >
              <div
                onClick={() => setEdited && setEdited(true)}
                className="cursor-pointer rounded hover:bg-gray-50"
              >
                {children}
              </div>
            </Tooltip>
          )}
        </>
      )
    }

    return (
      <>
        <Tooltip
          classNameConfig={{
            content: !tooltipElement?.title ? 'hidden' : ''
          }}
          content={tooltipElement?.title}
          position={tooltipElement?.position || 'top'}
          align={tooltipElement?.align || 'end'}
        >
          <div
            onClick={(e) => {
              // @ts-expect-error
              const getClassNamePreventClickable = e.target?.className
              if (
                getClassNamePreventClickable.search('prevent-clickable') < 0
              ) {
                setEdited && setEdited(true)
              }
            }}
            className="cursor-pointer rounded hover:bg-gray-50"
          >
            {children}
          </div>
        </Tooltip>

        {edited ? (
          <div
            ref={ref}
            className={cn('absolute bottom-0 left-0 right-0 top-0 z-10')}
          >
            <Tooltip
              open={!!destructiveText}
              position={tooltipError?.position || 'top'}
              align={tooltipError?.align || 'end'}
              content={destructiveText}
              classNameConfig={{
                content: !!destructiveText ? '' : 'hidden'
              }}
            >
              <div className="h-full bg-white">{component}</div>
            </Tooltip>
          </div>
        ) : null}
      </>
    )
  }

  return <div className="relative">{renderedView()}</div>
}

interface InlineEditingNoActionsNativeSelectProps
  extends InlineEditingNoActionsOptionsProps {
  onChange: (value?: ISelectOption) => Promise<boolean>
  onCancelUpdate?: (value?: ISelectOption) => void
  value?: ISelectOption
  size?: SelectSizeProps
  placeholder?: string
  options?: Array<ISelectOption>
  classNameOverride?: SelectClassControl
  destructiveText?: string
  creatable?: boolean
  isClearable?: boolean
  isDisabled?: boolean
  configSelectOption?: SelectOptionProps
}

const InlineEditingNoActionsNativeSelect = ({
  size,
  onChange,
  onCancelUpdate,
  children,
  value,
  placeholder,
  options = [],
  classNameOverride,
  destructiveText,
  creatable,
  isClearable = true,
  isDisabled,
  ...props
}: InlineEditingNoActionsNativeSelectProps) => {
  const [valueState, setValueState] = useState(value)
  const [oldValueState, setOldValueState] = useState(value)
  const [edited, setEdited] = useState(false)

  useEffect(() => {
    setValueState(value || undefined)
  }, [value])

  useEffect(() => {
    if (edited) {
      setOldValueState(valueState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited])

  return (
    <InlineEditingNoActionsControl
      {...props}
      autoSave={false}
      destructiveText={destructiveText}
      onClick={() => {
        if (onChange) {
          setEdited(false)
          onChange(valueState)
        }
      }}
      onCancel={() => {
        setEdited(false)
        setValueState(oldValueState)
        onCancelUpdate && onCancelUpdate(oldValueState)
      }}
      component={
        <NativeSelect
          classNameOverride={classNameOverride}
          size={size}
          onChange={(newValue) => {
            setValueState(newValue as ISelectOption)
            if (onChange) {
              setEdited(false)
              onChange(newValue as ISelectOption)
            }
          }}
          placeholder={placeholder}
          value={valueState}
          options={options}
          destructive={!!destructiveText}
          creatable={creatable}
          isClearable={isClearable}
          isDisabled={isDisabled}
          configSelectOption={props.configSelectOption}
        />
      }
      edited={edited}
      setEdited={setEdited}
    >
      {children}
    </InlineEditingNoActionsControl>
  )
}

InlineEditingNoActionsNativeSelect.displayName =
  'InlineEditingNoActionsNativeSelect'

interface InlineEditingNoActionsDateOrYearPickerProps
  extends InlineEditingNoActionsOptionsProps {
  onChange: (value?: DatePickerValue) => Promise<boolean>
  onCancelUpdate?: (value?: DatePickerValue) => void
  value?: DatePickerValue
  placeholder?: string
  defaultTab?: 'date' | 'year'
  inlineType: string
}

const InlineEditingNoActionsDateOrYearPicker = ({
  onChange,
  onCancelUpdate,
  children,
  value,
  placeholder,
  destructiveText,
  defaultTab,
  inlineType = 'default',
  ...props
}: InlineEditingNoActionsDateOrYearPickerProps) => {
  const [valueState, setValueState] = useState(value)
  const [oldValueState, setOldValueState] = useState(value)
  const [edited, setEdited] = useState(false)

  useEffect(() => {
    setValueState(value || undefined)
  }, [value])

  useEffect(() => {
    if (edited) {
      setOldValueState(valueState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited])

  return (
    <InlineEditingNoActionsControl
      {...props}
      autoSave={false}
      destructiveText={destructiveText}
      onClick={() => {
        if (onChange) {
          setEdited(false)
          onChange(valueState)
        }
      }}
      onCancel={() => {
        setEdited(false)
        // setValueState(oldValueState)
        onCancelUpdate && onCancelUpdate(oldValueState)
      }}
      component={
        <SingleDateWithYearOnlyPicker
          type={inlineType}
          config={{
            onChange: (value) => {
              onChange(value)
            },
            value: valueState,
            showClearIndicator: true,
            // onClear: () => {
            //   onChange({
            //     date: null,
            //     month: null,
            //     year: null
            //   })
            // }
          }}
          defaultTab={!valueState?.date ? 'year' : 'date'}
        />
      }
      edited={edited}
      setEdited={setEdited}
    >
      {children}
    </InlineEditingNoActionsControl>
  )
}

InlineEditingNoActionsDateOrYearPicker.displayName =
  'InlineEditingNoActionsDateOrYearPicker'

interface InlineEditingNoActionsAsyncSingleSearchWithSelectProps
  extends InlineEditingNoActionsOptionsProps {
  value?: Array<ISelectOption>
  onChange: (newValue: MultiValue<ISelectOption>) => Promise<boolean>
  onCancelUpdate?: (newValue: MultiValue<ISelectOption>) => void
  size?: SelectSizeProps
  placeholder?: string
  promiseOptions?: (
    params: IPromiseSearchOption
  ) => Promise<{ metadata?: { totalCount: number }; collection: never[] }>
  classNameOverride?: SelectClassControl
  destructiveText?: string
  creatable?: boolean
  configSelectOption?: SelectOptionProps
  menuIsOpen?: boolean
  isClearable?: boolean
  onInputChange?: AsyncSingleSearchWithSelectProps['onInputChange']
  isDisabled?: boolean
  loadAsyncWhenOpen?: boolean
  limit?: number
}

const InlineEditingNoActionsAsyncSingleSearchWithSelect = ({
  size,
  onChange,
  onCancelUpdate,
  children,
  value,
  placeholder,
  promiseOptions,
  classNameOverride,
  destructiveText,
  creatable = false,
  isClearable = true,
  isDisabled,
  loadAsyncWhenOpen = true,
  limit = 25,
  ...props
}: InlineEditingNoActionsAsyncSingleSearchWithSelectProps) => {
  const [valueState, setValueState] = useState(value || [])
  const [oldValueState, setOldValueState] = useState(value || [])
  const [edited, setEdited] = useState(false)

  const { menuIsOpen, onInputChange, ...inlineEditingProps } = props

  useEffect(() => {
    setValueState(value || [])
  }, [value])

  useEffect(() => {
    if (edited) {
      setOldValueState(valueState)
    } else {
      onInputChange &&
        onInputChange('', { action: 'input-change', prevInputValue: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited])

  return (
    <InlineEditingNoActionsControl
      {...inlineEditingProps}
      autoSave={false}
      destructiveText={destructiveText}
      onClick={() => {
        if (onChange) {
          setEdited(false)
          onChange(valueState)
        }
      }}
      onCancel={() => {
        setEdited(false)
        setValueState(oldValueState)
        onCancelUpdate && onCancelUpdate(oldValueState)
      }}
      component={
        <AsyncSingleSearchWithSelect
          loadAsyncWhenOpen={loadAsyncWhenOpen}
          promiseOptions={promiseOptions}
          classNameOverride={classNameOverride}
          size={size}
          configSelectOption={props.configSelectOption}
          onChange={(newValue) => {
            setValueState(
              newValue ? (Array.isArray(newValue) ? newValue : [newValue]) : []
            )
            if (onChange) {
              setEdited(false)
              onChange(
                newValue
                  ? Array.isArray(newValue)
                    ? newValue
                    : [newValue]
                  : []
              )
            }
          }}
          placeholder={placeholder}
          value={valueState}
          destructive={!!destructiveText}
          creatable={creatable}
          menuIsOpen={menuIsOpen}
          onInputChange={onInputChange}
          isClearable={isClearable}
          isDisabled={isDisabled}
        />
      }
      edited={edited}
      setEdited={setEdited}
    >
      {children}
    </InlineEditingNoActionsControl>
  )
}

InlineEditingNoActionsAsyncSingleSearchWithSelect.displayName =
  'InlineEditingNoActionsAsyncSingleSearchWithSelect'

interface InlineEditingNoActionsMultipleSelectProps
  extends InlineEditingNoActionsOptionsProps {
  onChange: (value?: Array<ISelectOption>) => Promise<boolean>
  onCancelUpdate?: (value?: Array<ISelectOption>) => void
  value?: Array<ISelectOption>
  size?: SelectSizeProps
  placeholder?: string
  options?: Array<ISelectOption>
  classNameOverride?: SelectClassControl
  destructiveText?: string
  creatable?: boolean
}

const InlineEditingNoActionsMultipleSelect = ({
  size,
  onChange,
  onCancelUpdate,
  children,
  value = [],
  placeholder,
  options = [],
  classNameOverride,
  destructiveText,
  creatable = false,
  ...props
}: InlineEditingNoActionsMultipleSelectProps) => {
  const [valueState, setValueState] = useState(value)
  const [oldValueState, setOldValueState] = useState(value)
  const [edited, setEdited] = useState(false)

  useEffect(() => {
    setValueState(value || [])
  }, [value])

  useEffect(() => {
    if (edited) {
      setOldValueState(valueState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited])

  return (
    <InlineEditingNoActionsControl
      {...props}
      autoSave={true}
      destructiveText={destructiveText}
      onClick={() => {
        if (onChange) {
          setEdited(false)
          onChange(valueState)
        }
      }}
      onCancel={() => {
        setEdited(false)
        setValueState(oldValueState)
        onCancelUpdate && onCancelUpdate(oldValueState)
      }}
      component={
        <MultipleSelect
          classNameOverride={classNameOverride}
          size={size}
          onChange={(newValue) => setValueState(newValue as ISelectOption[])}
          placeholder={placeholder}
          value={valueState}
          options={options}
          destructive={!!destructiveText}
          creatable={creatable}
        />
      }
      edited={edited}
      setEdited={setEdited}
    >
      {children}
    </InlineEditingNoActionsControl>
  )
}

InlineEditingNoActionsMultipleSelect.displayName =
  'InlineEditingNoActionsMultipleSelect'

interface InlineEditingNoActionsAsyncMultipleSearchWithSelectProps
  extends InlineEditingNoActionsOptionsProps {
  value?: Array<ISelectOption>
  onChange: (newValue: MultiValue<ISelectOption>) => Promise<boolean>
  onCancelUpdate?: (newValue: MultiValue<ISelectOption>) => void
  size?: SelectSizeProps
  placeholder?: string
  promiseOptions?: (
    params: IPromiseSearchOption
  ) => Promise<{ metadata?: { totalCount: number }; collection: never[] }>
  classNameOverride?: SelectClassControl
  configSelectOption?: SelectOptionProps
  destructiveText?: string
  creatable?: boolean
  isDisabled?: boolean
  onInputChange?: AsyncMultipleSearchWithSelectProps['onInputChange']
  isValidNewOption?: SelectProps['isValidNewOption'] // don't use re-render func in this func
  callbackClearSearchData?: () => void
  limit?: number
  loadAsyncWhenOpen?: boolean
  autoUpdate?: boolean
  isClearable?: boolean
}

const InlineEditingNoActionsAsyncMultipleSearchWithSelect = ({
  size,
  onChange,
  onCancelUpdate,
  children,
  value,
  placeholder,
  promiseOptions,
  classNameOverride,
  configSelectOption,
  destructiveText,
  creatable = false,
  isDisabled,
  onInputChange,
  isValidNewOption,
  callbackClearSearchData,
  limit = 25,
  isClearable = true,
  loadAsyncWhenOpen = true,
  autoUpdate = false,
  ...props
}: InlineEditingNoActionsAsyncMultipleSearchWithSelectProps) => {
  const [valueState, setValueState] = useState(value || [])
  // const [oldValueState, setOldValueState] = useState(value || [])
  const [edited, setEdited] = useState(false)

  useEffect(() => {
    if (autoUpdate) {
      setValueState(value || [])
    }
  }, [value])

  // useEffect(() => {
  //   if (edited) {
  //     setOldValueState(valueState)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [edited])

  return (
    <InlineEditingNoActionsControl
      {...props}
      autoSave={true}
      destructiveText={destructiveText}
      onClick={() => {
        // if (onChange) {
        setEdited(false)
        // onChange(valueState)
        // }
      }}
      // onCancel={() => {
      //   setEdited(false)
      //   setValueState(oldValueState)
      //   onCancelUpdate && onCancelUpdate(oldValueState)
      //   callbackClearSearchData && callbackClearSearchData()
      // }}
      component={
        <AsyncMultipleSearchWithSelect
          // limit={limit}
          isClearable={isClearable}
          loadAsyncWhenOpen={loadAsyncWhenOpen}
          promiseOptions={promiseOptions}
          classNameOverride={{
            loadingIndicator: '!p-0 !w-[10px]',
            ...classNameOverride
          }}
          size={size}
          onChange={(newValue) => {
            const newArray = newValue
              ? Array.isArray(newValue)
                ? newValue
                : [newValue]
              : []
            setValueState(newArray)
            onChange(newArray)
          }}
          isValidNewOption={isValidNewOption}
          placeholder={placeholder}
          value={valueState}
          configSelectOption={configSelectOption}
          destructive={!!destructiveText}
          creatable={creatable}
          isDisabled={isDisabled}
          onInputChange={onInputChange}
          callbackClearSearchData={callbackClearSearchData}
        />
      }
      edited={edited}
      setEdited={setEdited}
    >
      {children}
    </InlineEditingNoActionsControl>
  )
}

InlineEditingNoActionsAsyncMultipleSearchWithSelect.displayName =
  'InlineEditingNoActionsAsyncMultipleSearchWithSelect'

export {
  InlineEditingNoActionsAsyncMultipleSearchWithSelect,
  InlineEditingNoActionsAsyncSingleSearchWithSelect,
  InlineEditingNoActionsMultipleSelect,
  InlineEditingNoActionsNativeSelect,
  InlineEditingNoActionsDateOrYearPicker
}
export type { InlineEditingNoActionsOptionsProps }
