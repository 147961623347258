'use client'

import { ReactNode, cloneElement, forwardRef } from 'react'
import { InputVariantProps } from '~/core/ui/Input'
import { compact, getValidChildren } from '~/core/ui/utils'

interface InputGroupProps {
  children: ReactNode
  className?: string
  variant?: InputVariantProps
  size?: 'lg' | 'md' | 'sm' | 'xs'
  configPadding?: {
    left: string
    right: string
  }
}

const InputGroup = forwardRef<HTMLDivElement, InputGroupProps>(
  (
    {
      className = '',
      size = 'lg',
      variant = 'default',
      configPadding = {
        left: size === 'lg' ? ' pl-9' : ' pl-[34px]',
        right: size === 'lg' ? ' pr-9' : ' pr-[34px]'
      },
      ...props
    },
    ref
  ) => {
    const validChildren = getValidChildren(props.children)
    let groupClassName = ''

    validChildren.forEach((child: any) => {
      if (child.type.displayName === 'InputLeftAddon') {
        groupClassName += configPadding.left
      }
      if (child.type.displayName === 'InputRightAddon') {
        groupClassName += configPadding.right
      }
    })

    const clones = validChildren.map((child: any) => {
      const theming = compact({
        size: child.props?.size || size,
        variant: child.props?.variant || variant
      })

      return child.type.displayName !== 'Input'
        ? cloneElement(child, theming)
        : cloneElement(
            child,
            Object.assign(theming, { className: groupClassName }, child.props)
          )
    })

    return (
      <div ref={ref} className="relative">
        {clones}
      </div>
    )
  }
)

InputGroup.displayName = 'InputGroup'

export { InputGroup }
export type { InputGroupProps }
