'use client'

import { cva } from 'class-variance-authority'
import { useEffect, useMemo, useState } from 'react'
import { Matcher } from 'react-day-picker'
import { useTranslation } from 'react-i18next'
import { defaultFormatDate } from '../utilities/format-date'
import { CloseCircleFill } from './FillIcons'
import IconWrapper from './IconWrapper'
import { Input, InputVariantProps } from './Input'
import { Popover, PopoverContent, PopoverPortal, PopoverTrigger } from './Popover'
import { SingleDatePicker, SingleDatePickerSizeProps } from './SingleDatePicker'
import { DEFAULT_MIN_YEAR, SingleYearPicker } from './SingleYearPicker'
import { cn } from './utils'

interface DatePickerValue { year?: number | null, month?: number | null, date?: number | null }

interface SingleDateWithYearOnlyPickerProps {
  config?: {
    defaultOpen?: boolean
    onClick?: (event: React.MouseEvent<HTMLElement>) => void
    onChange: (value: DatePickerValue | undefined) => void
    onClear?: () => void
    defaultValue?: Date | undefined
    value?: DatePickerValue | undefined
    className?: string
    showOutsideDays?: boolean
    sideOffset?: number
    fromYear?: number
    toYear?: number
    disabled?: Matcher | Matcher[] | undefined
    showClearIndicator?: boolean
    id?: number
  }
  variant?: InputVariantProps
  size?: SingleDatePickerSizeProps
  autoUpdateValue?: boolean
  defaultTab?: 'date' | 'year'
  placeholder?: string
  type: string
}

const rightIconVariants = cva(
  'absolute top-0 bottom-0 text-gray-400 dark:text-gray-600 inline-flex items-center justify-center right-0',
  {
    variants: {
      size: {
        md: 'w-[18px] right-3',
        sm: 'w-4 right-[10px]'
      }
    },
    defaultVariants: {
      size: 'md'
    }
  }
)

const DEFAULT_DATE_PICKER_CONFIG = {
  defaultOpen: false,
  onClick: undefined,
  onChange: undefined,
  onClear: undefined,
  defaultValue: undefined,
  value: {
    year: new Date().getFullYear()
  },
  disabled: undefined,
  className: '',
  showOutsideDays: true,
  sideOffset: 'pt-1',
  fromYear: DEFAULT_MIN_YEAR,
  toYear: 2035,
  showClearIndicator: true,
  id: 1
}

export const formatDatePickerToDate = (value?: DatePickerValue) => {
  return !!value?.year  
    ? new Date(value?.year, 
      value?.month ? Number(value?.month) - 1 : 1, //month in Date count from 0
      value?.date ? Number(value?.date) : 1) 
    : new Date()
}

export const formatDateToDatePicker = (value?: Date) => {
  return value ? {
    year: value?.getFullYear(),
    month: Number(value?.getMonth()) >= 0 ? Number(value?.getMonth()) + 1 : null, //month in Date count from 0
    date: value?.getDate() || null
  } : undefined
}

const SingleDateWithYearOnlyPicker = (props: SingleDateWithYearOnlyPickerProps) => {
  const {
    config = DEFAULT_DATE_PICKER_CONFIG,
    variant = 'default',
    size = 'md',
    autoUpdateValue = false,
    defaultTab,
    placeholder,
    type = 'default'
  } = props
  const { t, i18n } = useTranslation()
  const [open, onOpenChange] = useState(false)
  const [activeTab, setActiveTab] = useState<string>(defaultTab || 'date')

  const dateYearTabs: {[key: string]: string} = useMemo(() => ({
    date: `${t('label:date')}`,
    year: `${t('label:year')}`
  }), [t])

  return (
    <div className="relative">
      <Popover
        open={open}
        onOpenChange={onOpenChange}>
        <PopoverTrigger asChild>
          <div className={`my-ignore-single-picker-${config.id} group relative`}>
            <div onClick={() => onOpenChange(true)}>
              <Input
                isFocus={open}
                variant={"default"}
                value={config.value?.year
                    ? activeTab === 'date' 
                      ? defaultFormatDate(formatDatePickerToDate(config.value))
                      : (config.value?.year || '')
                    : ''}
                size="sm"
                placeholder={placeholder || `${t('form:select')}`}
              />
            </div>
            <div
              className={cn(
                rightIconVariants({
                  size
                })
              )}
              onClick={(e) => {
                e.preventDefault()
                if (config.value?.year && config.showClearIndicator) {
                  // setSelectedDay(undefined)
                  config.onChange && config.onChange({
                    year: null,
                    month: null,
                    date: null
                  })
                  if (config.onClear) {
                    config.onClear()
                  }
                }
              }}>
              <div
                className={cn(
                  variant === 'ghost'
                    ? 'hidden group-focus-within:flex group-focus-within:items-center'
                    : ''
                )}>
                <div className="hidden cursor-pointer group-hover:block">
                  {!!config.value?.year && config.showClearIndicator ? (
                    <CloseCircleFill className="fill-gray-400 dark:fill-gray-500" />
                  ) : (
                    <IconWrapper size={16} name="Calendar" />
                  )}
                </div>
                <div className="block group-hover:hidden">
                  <IconWrapper size={16} name="Calendar" />
                </div>
              </div>
            </div>
            
          </div>
        </PopoverTrigger>

        <PopoverPortal>
          <PopoverContent
            align="start"
            className="w-full min-w-[300px] my-ignore-action pb-1">
            <div className="px-2 pt-2 mb-1">
              <div className="flex items-center justify-between rounded bg-gray-100 p-[1px]">
                {Object.keys(dateYearTabs).map((tab) => (
                  <button
                    type="button"
                    key={tab}
                    className={cn(
                      `w-1/2 group rounded px-2 py-[2px] font-medium text-gray-600 hover:text-gray-700 dark:bg-gray-700 dark:text-gray-300 dark:hover:text-gray-300`,
                      activeTab === tab
                        ? 'bg-white text-gray-900 shadow hover:bg-white hover:shadow'
                        : ''
                    )}>
                    <div
                      className="flex items-center justify-center space-x-2"
                      onClick={() => setActiveTab(tab)}>
                      <span className="text-xs font-medium">{dateYearTabs[tab]}</span>
                    </div>
                  </button>
                ))}
              </div>
            </div>
            {activeTab === 'date' 
              ? <SingleDatePicker
                  locale={i18n.language}
                  useOnlyFunction
                  autoUpdateValue
                  className="max-w-[107px]"
                  config={{
                    defaultOpen: true,
                    onChange: (newValue) => {
                      const formatDate = formatDateToDatePicker(newValue)
                      return config.onChange ? config.onChange(formatDate) : Promise.resolve()
                    },
                    value: config.value?.year ? formatDatePickerToDate(config.value) : undefined,
                    fromYear: 1920,
                    toYear: type === 'birthday' ? new Date().getFullYear() : new Date().getFullYear() + 50
                  }}
                  size="sm"
                /> 
              : (<div className="p-2">
                <SingleYearPicker
                  defaultEndRangeYear={type === 'birthday' ? new Date().getFullYear() : new Date().getFullYear() + 50}
                  value={config.value}
                  onChange={config.onChange}
                />
              </div>)
            }
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </div>
  )
}

SingleDateWithYearOnlyPicker.displayName = 'SingleDateWithYearOnlyPicker'

export { SingleDateWithYearOnlyPicker }
export type { SingleDateWithYearOnlyPickerProps, DatePickerValue }

