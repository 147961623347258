'use client'

import { cva } from 'class-variance-authority'
import { forwardRef } from 'react'
import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper'
import { cn } from '~/core/ui/utils'

export const dashedButtonRootVariants = cva(
  'flex items-center justify-center border border-dashed rounded-full',
  {
    variants: {
      variant: {
        default:
          'border-gray-300 hover:bg-gray-50 focus:border-primary-300 focus:ring-1 focus:ring-primary-300 dark:focus:ring-primary-700 focus:bg-transparent',
        disabled: 'border-gray-200'
      },
      size: {
        xs: 'w-4 h-4',
        sm: 'w-5 h-5',
        md: 'w-6 h-6',
        lg: 'w-8 h-8',
        xl: 'w-10 h-10'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'md'
    }
  }
)

const getTextButtonSizeIcon = {
  xl: 18,
  lg: 16,
  md: 14,
  sm: 12,
  xs: 10
}

type DashedButtonSizeProps = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

interface DashedButtonProps {
  size?: DashedButtonSizeProps
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  isDisabled?: boolean
  iconMenus: LucideIconName
  className?: string
}

const DashedButton = forwardRef<HTMLButtonElement, DashedButtonProps>(
  (props, ref) => {
    const {
      size = 'md',
      isDisabled = false,
      onClick,
      iconMenus,
      className
    } = props
    return (
      <button
        disabled={isDisabled}
        ref={ref}
        type="button"
        onClick={(event) => {
          if (onClick) {
            onClick(event)
          }
        }}
        className={cn(
          dashedButtonRootVariants({
            size,
            variant: isDisabled ? 'disabled' : 'default',
            className
          })
        )}>
        <IconWrapper
          size={getTextButtonSizeIcon[size]}
          name={iconMenus}
          className="text-gray-400 dark:text-gray-400"
        />
      </button>
    )
  }
)

DashedButton.displayName = 'DashedButton'

export { DashedButton }
export type { DashedButtonProps, DashedButtonSizeProps }
