import { useState } from 'react'
import { IAttachmentsFiles } from '~/core/ui/RichEditor'

export const MAXIMUM_5_MB = 5 * 1024 * 1024
export const MAXIMUM_10_MB = 10 * 1024 * 1024
export const ACCEPT_FILES_COMMON =
  'image/jpeg, image/png, image/jpg, application/pdf, application/msword, .doc, .docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
export const LIST_ACCEPT_FILES_COMMON = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'application/pdf',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.xlsx',
  '.xls',
  '.doc',
  '.docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
]
export const LIST_ACCEPT_FILES_PDF_DOC = [
  'application/pdf',
  'application/msword',
  '.doc',
  '.docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
]

const useUploadS3AWS = ({
  defaultFiles = []
}: {
  defaultFiles?: IAttachmentsFiles
}) => {
  const [files, setFiles] = useState<IAttachmentsFiles>(defaultFiles)

  return {
    files,
    setFiles
  }
}

export default useUploadS3AWS
