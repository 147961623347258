import { CustomFieldComponentType } from '~/components/CustomField'
import {
  CustomFieldFormType,
  CustomFieldResponseItem
} from '../types/custom-field'

export const mappingTypeToFieldKind = (type: string): string => {
  const dataMapping: { [key: string]: string } = {
    text: 'string',
    paragraph: 'text',
    number: 'number',
    toggle: 'boolean',
    select: 'array',
    date: 'date'
  }
  return dataMapping?.[type] || 'string'
}

export const mappingCustomFieldKind = (
  fieldKind: string
): CustomFieldComponentType['type'] => {
  const dataMapping: { [key: string]: CustomFieldComponentType['type'] } = {
    string: 'text',
    text: 'paragraph',
    number: 'number',
    boolean: 'toggle',
    array: 'select',
    date: 'date'
  }
  return dataMapping?.[fieldKind] || 'text'
}

export const renderKeyCustomFieldForm = ({
  fieldKind,
  id
}: {
  fieldKind: string
  id: number | string
}) => `${id}-${fieldKind}`

export const formatInitialValueCustomField = (
  data?: CustomFieldResponseItem[],
  extraConfig?: {
    isNotTrimText: boolean
  }
): CustomFieldFormType['customFields'] => {
  return (data || []).reduce((result, item) => {
    const customFieldType = mappingCustomFieldKind(item.fieldKind)
    return {
      ...result,
      [renderKeyCustomFieldForm({
        fieldKind: item.fieldKind,
        id: item.customSettingId
      })]: {
        ...item,
        id: item.customSettingId,
        type: customFieldType,
        value:
          customFieldType === 'date'
            ? Object.keys(item.value || {}).length
              ? item.value
              : { year: null, month: null, date: null }
            : customFieldType === 'select'
            ? item.selectedOptionKeys?.[0]
            : typeof item.value === 'string' && !extraConfig?.isNotTrimText
            ? (item.value || '').trim()
            : item.value
      }
    }
  }, {})
}

export const formatSubmitCustomFieldData = (
  data?: CustomFieldFormType['customFields'],
  extraConfig?: {
    isNotTrimText: boolean
  }
) => {
  return Object.values(data || {}).map((item) => {
    return {
      index: Number(item.index),
      fieldKind: mappingTypeToFieldKind(item.type),
      customSettingId: Number(item.id),
      value:
        item.type === 'date'
          ? Object.keys(item.value || {}).length
            ? item.value
            : { year: null, month: null, date: null }
          : item.type === 'select'
          ? ''
          : typeof item.value === 'string' && !extraConfig?.isNotTrimText
          ? item.value.trim()
          : item.value || '',
      selectedOptionKeys: item.type === 'select' ? [item.value] : []
    }
  })
}
