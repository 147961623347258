import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ComboboxSelect from "./ComboboxSelect";
import { IconButton } from "./IconButton";
import { ISelectOption } from "./Select";
import { DatePickerValue } from "./SingleDateWithYearOnlyPicker";

interface SingleYearPickerProps {
    value?: DatePickerValue
    onChange?: (value?: DatePickerValue) => void
    defaultEndRangeYear: number
}

const DEFAULT_STEP_YEAR = 12
export const DEFAULT_MIN_YEAR = 1920

const getEndYear = (startYear: number) => startYear + DEFAULT_STEP_YEAR - 1

const arrayRange = (start: number, stop: number, step: number) =>
  Array.from(
  { length: (stop - start) / step + 1 },
  (value, index) => start + index * step
  );

const getYearRangeList = (currentYear: number): ISelectOption[] => {
  let maximumCurrentYearOption = DEFAULT_MIN_YEAR
  let yearOptions: ISelectOption[] = [] 

  while(maximumCurrentYearOption <= currentYear) {
    yearOptions.push({
      value: String(maximumCurrentYearOption),
      supportingObj: {
        name: `${maximumCurrentYearOption} - ${getEndYear(maximumCurrentYearOption)}`
      }
    })

    maximumCurrentYearOption += DEFAULT_STEP_YEAR
  }

  return yearOptions
}

const SingleYearPicker: FC<SingleYearPickerProps> = (props) => {
    const { value, defaultEndRangeYear, onChange } = props
    const parentRef = useRef<HTMLDivElement>(null)
    const [startYearSelected, setStartYearSelected] = useState<ISelectOption>() // value of ISelection is Start Year

    const yearOptions = useMemo(() => getYearRangeList(defaultEndRangeYear), [defaultEndRangeYear])

    const backToPreviousRangeYear = useCallback(() => {
      const year = Number(startYearSelected?.value)
      const indexSelectedYearRange =  yearOptions.findIndex((yearRange) => {
        const startYear = Number(yearRange.value)
        const endYear = getEndYear(startYear)

        return year >= startYear && year <= endYear
      })
      
      if (indexSelectedYearRange === 0) return

      setStartYearSelected(yearOptions[indexSelectedYearRange - 1])
    }, [startYearSelected, yearOptions])

    const nextRangeYear = useCallback(() => {
      const year = Number(startYearSelected?.value)
      const indexSelectedYearRange =  yearOptions.findIndex((yearRange) => {
        const startYear = Number(yearRange.value)
        const endYear = getEndYear(startYear)

        return year >= startYear && year <= endYear
      })
      
      if (indexSelectedYearRange === (yearOptions.length - 1)) return

      setStartYearSelected(yearOptions[indexSelectedYearRange + 1])
    }, [startYearSelected, yearOptions])

    useEffect(() => {
      const year = value?.year || new Date().getFullYear()
      const yearRangeSelected = yearOptions.find((yearRange) => {
        const startYear = Number(yearRange.value)
        const endYear = getEndYear(startYear)

        return year >= startYear && year <= endYear
      })

      setStartYearSelected(yearRangeSelected)
    }, [value, yearOptions])

    return (
      <div ref={parentRef}>
        <div className="py-1 pl-3 flex items-center justify-between">
          <div>
            <ComboboxSelect
              type="unstyled"
              closeOnSelect
              configSelectOption={{
                supportingText: ['name']
              }}
              onChange={(value) => setStartYearSelected(value as ISelectOption)}
              size="sm"
              value={startYearSelected}
              isSearchable={false}
              menuOptionSide="bottom"
              isClearable={false}
              menuOptionAlign="start"
              dropdownMenuClassName="min-w-[106px] max-w-[114px]"
              buttonClassName="max-w-[114px]"
              options={yearOptions}
            />
          </div>
          <div className="flex items-center">
            <IconButton
              onClick={backToPreviousRangeYear}
              type="secondary"
              size="sm"
              iconMenus="ChevronLeft"
              className="focus:ring-0 focus:shadow-none focus:ring-white"
            />
            <IconButton
              onClick={nextRangeYear}
              type="secondary"
              size="sm"
              iconMenus="ChevronRight"
              className="focus:ring-0 focus:shadow-none focus:ring-white"
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-y-1">
          {arrayRange(Number(startYearSelected?.value), getEndYear(Number(startYearSelected?.value)), 1).map((year) => (
            <button key={`year-option-${year}`}
              onClick={() => {
                onChange && onChange({
                  year: year,
                  month: null,
                  date: null
                })
              }}
              className="text-sm text-gray-700 h-10 p-1 w-full inline-flex items-center justify-center rounded hover:bg-gray-100">
                {year === value?.year 
                  ? <div className="text-white bg-primary-400 h-full w-full rounded leading-[13px] inline-flex items-center justify-center">
                    {year}
                  </div> 
                  : year}
            </button>
          ))}
        </div>
    </div>
    )
}

SingleYearPicker.displayName = 'SingleYearPicker'

export { SingleYearPicker }
export type { SingleYearPickerProps }